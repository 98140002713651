body {
  background-color: #080908;
  color: #fff;
  box-sizing: border-box;
}
.container {
  width: 100%;
  max-width: 1152px;
  @apply mx-auto px-4;
}

.react-tooltip {
  z-index: 999;
  background-color: #fff;
  border-radius: 12px;
}

.hhhTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 24px !important;
  max-height: 72px !important;
}

.spinner {
  display: inline-block;
  position: relative;
}

.spinner-small {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.spinner-medium {
  width: 40px;
  height: 40px;
}

.spinner div {
  animation: spinner 1.2s linear infinite;
}

.spinner-small div {
  transform-origin: 10px 10px;
}

.spinner-medium div {
  transform-origin: 20px 20px;
}

.spinner div:after {
  content: '';
  display: block;
  position: absolute;
}

.spinner-small div:after {
  top: 1px;
  left: 9px;
  width: 2px;
  height: 5px;
  border-radius: 4px;
}

.spinner-medium div:after {
  top: 1px;
  left: 18px;
  width: 4px;
  height: 9px;
  border-radius: 4px;
}

.spinner-primary div:after {
  @apply bg-primary;
}

.spinner-secondary div:after {
  @apply bg-secondary;
}

.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.MuiSlider-root.slider-stock {
  color: #9CA199;
  padding: 2px 0;

  .MuiSlider-rail {
    opacity: 1;
  }

  &.Mui-active {
    .MuiSlider-thumb {
      opacity: 1;
    }
  }

  .MuiSlider-thumb {
    opacity: 0;

    &.Mui-active {
      opacity: 1;
    }
  }

  .MuiSlider-track {
    background-color: #00c802;
    border: none;
  }

  .MuiSlider-mark {
    width: 8.087px;
    height: 8.087px;
    top: 2px;
    transform: rotate(-45deg) translate(-1px, -50%);
    border: 0.5px solid #9CA199;
    background-color: #181B18;

    &.MuiSlider-markActive {
      border-color: #00c802;
      background-color: #00c802;
    }
  }
}

.order-button {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &--B {
    &::before {
      left: 0;
      right: -5px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      background-position: top right;
    }

    &.active {
      &::before {
        background-image: url('../images/tab_buy.png');
      }
    }
  }

  &--S {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-position: top left;

    &::before {
      right: 0;
      left: -5px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      background-position: top left;
    }

    &.active {
      &::before {
        background-image: url('../images/tab_sell.png');
      }
    }
  }
}

.box-items {
  @apply border bg-main_background_white;
  padding: 16px 16px 64px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  height: 100%;
  max-height: 300px;
}

.bg-popup-sidebar {
  background: rgba(12, 17, 29, 0.63);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
