.stock-table {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.order-book-table {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.stock-table thead tr {
  background: #080908;
}

.stock-table thead th {
  padding: 8px 0px 8px 32px;
  display: flex;
  gap: 2px;
  width: 20%;
  min-height: 36px;
  justify-content: right;
  font-weight: 400;
}

.money-history-table thead th {
  justify-content: left;
}

.money-history-table thead th:last-child {
  justify-content: right;
}

.money-history-table thead th {
  padding: 8px 16px 8px 16px;
}

.stock-table tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #1c1c1c;
}

.money-history-table tr {
  border-bottom: 1px solid #475467;
}

.money-history-table thead tr {
  border-bottom: none;
}

.stock-table tbody tr {
  margin: 0px 32px;
}

.money-history-table tbody tr {
  margin: 0px 16px;
}

.money-history-table tbody tr:last-child {
  border-bottom: none;
}

.stock-table td {
  padding: 12px 0px 10px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 20%;
  justify-content: right;
}

.money-history-table td {
  padding: 14px 0px;
}

.stock-table td:first-child,
.stock-table thead th:first-child,
.money-history-table td {
  justify-content: left;
}

.money-history-table td:last-child {
  justify-content: right;
}

.stock-table.stock-table-small {
  tbody tr {
    margin-left: 16px;
    margin-right: 16px;
  }

  thead th {
    padding-left: 16px;
    width: 25%;

    &:last-child {
      padding-right: 16px;
    }
  }

  td {
    width: 25%;
  }
}

.scroll-table-style {
  position: relative;
  padding-right: 3px;
  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 37px;
    width: 4px;
    background-color: #080908;
    border-bottom: 1px solid #1c1c1c;
  }
  .stock-table {
    tbody {
      display: block;
      height: 288px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: #475467;
        border-radius: 9999px;
      }
    }
  }
}

.scroll-table-style .stock-table tbody tr {
  // display: table;
  max-width: 87%;
  table-layout: fixed;
}

// hiden scroll bar
.transaction-history-table tbody::-webkit-scrollbar {
  display: none;
}

.order-book-table {
  tbody tr {
    border-bottom: 1px solid #303630;
  }
  td {
    padding: 8px 5px;

    &:first-child {
      padding-left: 12px;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  th {
    padding: 8px 5px;

    &:first-child {
      padding-left: 12px;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.enterprise-table td {
  padding: 16px 0px;
}

.order-book-table tr th:first-child,
.order-book-table tr th:nth-child(3),
.order-book-table tr td:first-child,
.order-book-table tr td:nth-child(3) {
  width: 18%;
  text-align: right;
}

.order-book-table tr th:nth-child(2),
.order-book-table tr td:nth-child(2) {
  text-align: left;
}

.order-book-table tr th:nth-child(4),
.order-book-table tr th:nth-child(5),
.order-book-table tr td:nth-child(4),
.order-book-table tr td:nth-child(5) {
  text-align: right;
  width: 20%;
}

.order-book-table tr th:nth-child(6),
.order-book-table tr td:nth-child(6) {
  width: 25%;
}

.order-book-table thead th {
  padding: 8px 0px;
  color: #DADDDA;
  border-bottom: 1px solid #303630;
}

.asset_table tbody td {
  border-bottom: 1px solid #303630 !important;
  background-color: #080908;
}

.order-history-table tbody tr {
  border-bottom: 1px solid #303630 !important;
}

.order-history-table tbody tr td {
  padding: 8px 0px;
}

.order-book-table tbody tr:last-child {
  border-bottom: none;
}

.order-book-table tr th:first-child,
.order-book-table tr td:first-child {
  text-align: left;
}

.transaction-history-table thead th {
  padding: 8px 0px;
}

.scrollable-table-body tbody {
  display: block;
  max-height: 400px; /* Maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.scrollable-table-body thead,
.scrollable-table-body tbody tr {
  display: table;
  width: 100%; /* Table width is 100% */
  table-layout: fixed; /* Fixed table layout to maintain column width */
}

.ant-table {
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    color: #fff;
  }
}

.ant-table-row > .ant-table-cell-row-hover {
  background: #181B18 !important;
}

.ant-table-content {
  padding-bottom: 8px;
}

.ant-table-content::-webkit-scrollbar-thumb {
  background: #344054;
  border-radius: 8px;
}

.ant-table-content::-webkit-scrollbar {
  height: 4px;
}

.asset_table .ant-table {
  font-size: 12px;
  line-height: 18px;

  td {
    font-size: 14px;
    line-height: 20px;
  }

  .ant-table-column-sorters {
    justify-content: end;
  }

  th {
    &:nth-child(2) {
      .ant-table-column-sorters {
        justify-content: start;
      }
    }

    letter-spacing: -0.12px;
  }

  .ant-table-column-title {
    flex: none;
  }

  .ant-table-thead {
    tr {
      .ant-table-cell {
        padding-top: 0;
        padding-bottom: 8px;
      }

      &:first-child {
        .ant-table-cell {
          padding-top: 8px;
          padding-bottom: 8px;

          &[scope='colgroup'] {
            padding-bottom: 4px;
          }
        }
      }

      th {
        font-weight: normal;
        background-color: #303630;
        border: none;
      }
    }
  }
  .ant-table-tbody {
    font-weight: 500;
  }

  .ant-table-placeholder td.ant-table-cell:hover,
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: #181b18 !important;
  }

  .ant-empty-description {
    color: #898e87;
  }

  .ant-empty-image svg path {
    fill: #898e87 !important;
    stroke: #080908 !important;
  }

  .ant-table-cell {
    padding: 15px 0;
    text-align: center;
    border-radius: 0px !important;

    &:first-child {
      border: none;
    }

    &:last-child {
      border: none;
    }

    &::before {
      display: none;
    }
  }
}

.hover-buy-stock {
  .hover-buy-button {
    display: none;
  }
}

.hover-buy-stock:hover {
  .hover-buy-button {
    display: flex;
  }

  .hover-stock-volume {
    display: none;
  }
}

.cert-table {
  width: 100%;
}

.cert-table th {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.12px;
  padding: 18px 0px;
  text-align: left;
  background-color: #080908;
  width: 200px;
}

.cert-table th:first-child,
.cert-table td:first-child {
  padding-left: 16px;
}

.cert-table th:last-child,
.cert-table td:last-child {
  padding-right: 16px;
}

.cert-table td {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 14px 0px;
}
