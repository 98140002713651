.Toastify__toast-container {
  padding: 0px !important;
  top: 2rem !important;
  width: 464px !important;
}

.Toastify__toast {
  min-height: 0px !important;
  padding: 13px 17px 13px 17px !important;
  border-radius: 4px !important;
}

.Toastify__toast > .Toastify__toast-body {
  padding: 0px !important;
}

.Toastify__toast > .Toastify__toast-body > div {
  @apply font-primary;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #fff !important;
}

.Toastify__toast > .Toastify__toast-body > .Toastify__toast-icon > svg {
  width: 22px !important;
  height: 22px !important;
}

.Toastify__toast > .Toastify__close-button {
  opacity: 1 !important;
  align-self: center !important;
  color: #fff !important;
  @apply font-primary;
}

.Toastify__toast--success {
  background-color: #002500 !important;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
}

.Toastify__toast--error {
  background-color: #2d0600 !important;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
}

.toastify {
  padding: 10px 16px !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  top: 120px !important;
  right: 70px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

.toast-close {
  padding: 0;
  margin-left: 8px;
  color: #fff !important;
  width: 20px;
  height: 20px;
  opacity: 1;
}
