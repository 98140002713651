.input-search-stock {
  padding: 10px 92px;
  height: 40px;
  width: 100%;
  background: transparent;
  border-radius: 6px;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.checkbox {
}
.checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.checkbox__label {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
}
.checkbox__label:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: #080908;
  border: 1px solid #303630;
}

.two_factor_checkbox:before {
  border-radius: 2px;
}

.checkbox__input:checked ~ .two_factor_checkbox:before {
  background-color: #00c802;
}

.checkbox__input:checked ~ .checkbox__label:before {
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K');
}
.checkbox__input:disabled ~ .checkbox__label {
  color: #98a2b3;
}

.checkbox__input:checked:disabled ~ .checkbox__label:before {
  opacity: 0.5;
}

.checkbox__input:disabled ~ .checkbox__icon {
  opacity: 0.5;
}

.checkbox__icon {
  position: relative;
  cursor: pointer;
  display: block;
  width: 14px;
  height: 14px;
  border: 2px solid #a4a4a4;

  .check {
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    opacity: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.checkbox__input:checked ~ .checkbox__icon {
  border-color: #00c802;
  // background-color: #00C802;
  .check {
    opacity: 1;
  }
}

.form-input {
  display: flex;
  flex-direction: column;
  gap: 6px;

  textarea {
    resize: none;
    max-height: 64px;
    height: 64px;
  }
}

.form-input__label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}

.form-input__label_account-info {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.6px;
  color: #ffffff;
}

.form-input__input {
  @apply border bg-main_background_white;
  padding: 12px 16px;
  width: 100%;
  max-height: 44px;
  border-radius: 4px;
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.form-input__input-editable {
  @apply bg-black;
}

.form-input__input_error {
  border: 1px solid #f04438;
}

.form-input__input::placeholder {
  color: #475467;
  opacity: 1;
}

.form-input__input_disabled {
  background: #626860;
  color: #898e87;
}
