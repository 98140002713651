.react-select {
  height: 46px;
  // border-radius: 4px;
  // border: 1px solid #E5E5E5;
  @apply text-sm xl:text-base;

  .select__single-value {
    @apply text-sm xl:text-base text-black font-medium;
  }

  .select__control {
    height: 44px;
    border-radius: 4px;
    padding-left: -1px;
    box-shadow: none !important;
    @apply border border-stroke_default bg-main_background_white text-white;
  }

  .select__control:hover {
    @apply border border-primary;
    box-shadow: none;
  }

  .select__value-container {
    padding-left: 14px;
    padding-right: 6px;
  }

  .select__indicator-separator {
    border: none;
    display: none;
  }

  .select__input {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .select__placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #98a2b3;
  }

  .select__input:disabled {
    color: #7d7d7d !important;
  }

  .select__single-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: white;
  }

  .select__single-value.select__single-value--is-disabled {
    color: #7d7d7d !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .select__menu {
    margin-top: 2px;
    @apply bg-black border border-tab_background;
  }

  .select__menu > .select__menu-list {
    border-radius: 6px;
    @apply p-4 bg-black;

    & > .select__option {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      @apply h-52px bg-black border-gray border-b flex items-center;
    }

    & > .select__option:last-child {
      @apply border-none;
    }

    & > .select__option:active {
      @apply bg-black;
    }

    & > .select__option:focus-within {
      @apply bg-black;
    }

    .select__option--is-selected {
      @apply bg-blackBlueLight;
    }

    .select__option--is-focused {
      @apply bg-blackBlueLight;
    }
  }

  .select__menu-list::-webkit-scrollbar {
    width: 2px;
  }

  .select__menu-list::-webkit-scrollbar-thumb {
    @apply bg-primary;
  }

  .select__option,
  .select__option--is-selected {
  }

  .select__indicator {
    margin-right: 8px;
  }
}

.react-select-popup {
  height: 44px;
  // border-radius: 4px;
  // border: 1px solid #E5E5E5;
  @apply text-sm xl:text-base text-text_main_light;

  .select__single-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    @apply bg-blackBlue text-text_main_light font-medium;
  }

  .select__control {
    height: 44px;
    border-radius: 4px;
    padding-left: -1px;
    @apply border border-tab_background bg-blackBlue text-text_main_light;
  }

  .select__value-container {
    padding-left: 14px;
    padding-right: 6px;
    color: #98a2b3;
  }

  .select__indicator-separator {
    border: none;
    display: none;
  }

  .select__input-container {
    color: #98a2b3 !important;
  }

  .select__input {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #98a2b3 !important;
  }
  .select__placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #98a2b3;
  }

  .select__input:disabled {
    color: #7d7d7d !important;
  }

  .select__single-value.select__single-value--is-disabled {
    color: #7d7d7d !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .select__menu {
    margin-top: 2px;
    max-height: 270px;
    @apply bg-black border border-tab_background;
  }

  .select__menu > .select__menu-list {
    max-height: 265px;
    @apply p-4 bg-blackBlue;

    & > .select__option {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      @apply h-52px bg-blackBlue border-gray border-b flex items-center;
    }

    & > .select__option:last-child {
      @apply border-none;
    }

    & > .select__option:active {
      @apply bg-blackBlue;
    }

    & > .select__option:focus-within {
      @apply bg-blackBlue;
    }

    .select__option--is-selected {
      @apply bg-blackBlueLight;
    }

    .select__option--is-focused {
      @apply bg-blackBlueLight;
    }
  }

  .select__menu-list::-webkit-scrollbar {
    width: 2px;
  }

  .select__menu-list::-webkit-scrollbar-thumb {
    @apply bg-primary;
  }

  .select__option,
  .select__option--is-selected {
  }

  .select__indicator {
    margin-right: 8px;
  }
}
