@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../node_modules/react-tooltip/dist/react-tooltip.css';
@import './assets/styles/base.scss';
@import './assets/styles/table.scss';
@import './assets/styles/input.scss';
@import './assets/styles/toast.scss';
@import './assets/styles/select.scss';
@import './assets/styles/form.scss';

@layer base {
  body {
    margin: 0;
    padding: 0 0;
    background-color: #f4f4f4;
    box-sizing: border-box;
    @apply font-primary;
  }

  * {
    min-width: 0;
    min-height: 0;
  }

  input::placeholder {
    font-weight: 500;
    color: #000;
    opacity: 0.3;
  }

  .required-after:after {
    content: ' *';
    color: #f04438;
  }

  .required-before::before {
    content: ' *';
    color: #f04438;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hidden-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .scrollbar-custom::-webkit-scrollbar {
    width: 4px;
    height: 50px;
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    background: #475467;
    border-radius: 8px;
  }

  .mini-scrollbar::-webkit-scrollbar {
    width: 2px;
  }

  .mini-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-primary;
  }
}

.scrollbar-horizontal::-webkit-scrollbar-thumb,
.scrollbar-vertical::-webkit-scrollbar-thumb {
  background: #626860;
  border-radius: 8px;
}

.scrollbar-horizontal::-webkit-scrollbar {
  height: 4px;
}

.scrollbar-vertical::-webkit-scrollbar {
  width: 6px;
}

.diagonal-line-green {
  clip-path: polygon(0 0, 100% 0, 98% 100%, 0 100%);
}

.diagonal-line-yellow {
  clip-path: polygon(4% 0, 100% 0, 96% 100%, 0 100%);
}

.diagonal-line-red {
  clip-path: polygon(2% 0, 100% 0, 100% 100%, 0 100%);
}

.search-input {
  @apply border border-primary;
  padding: 12px 16px 12px 44px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  outline: none;
  background-color: transparent;
  color: #98a2b3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.search-input::placeholder {
  @apply text-text_main_med;
  opacity: 1;
}

.menu-profile {
  &::before {
    content: '';
    position: absolute;
    top: -11px;
    right: 13px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 12px solid #303630;
  }
}

.text-success {
  color: #00c802;
}

.text-wait-cancel {
  color: #eaaa08;
}

.isSelectStyle {
  background-color: #003600;
  color: #fff !important;

  .command-code {
    background-color: #00c802;
  }

  .command-code p {
    color: #fff !important;
  }
}

// make class fordatepicker to css placeholder color

.datepicker {
  .datepicker__input {
    background-color: #080908;
    color: white;
    border: 1px solid #303630;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .ant-picker-active-bar {
    background-color: #00c802;
  }

  .ant-picker-input input::placeholder {
    color: #999;
  }

  .ant-picker-separator {
    color: #999;
    margin-right: 10px;
  }

  .anticon-calendar svg,
  .anticon-close-circle svg {
    fill: #999;
  }
}

// style ant date picker
.ant-picker-panel-layout {
  background-color: #080908;
  border: 1px solid #303630;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.ant-picker-cell-disabled {
  color: #475467 !important;
}

.ant-picker-cell-in-range {
  color: #080908 !important;
}

.ant-picker-header {
  border-bottom: 1px solid #303630 !important;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-prev-btn,
.ant-picker-header-next-btn,
.ant-picker-header-super-next-btn {
  color: #fff !important;
}

.ant-picker-panel:first-child {
  .ant-picker-header,
  .ant-picker-body {
    border-right: 1px solid #303630 !important;
  }
}

.radio-button-style {
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #303630;
  margin-right: 0;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio-inner {
  border: 0.5px solid #00c802;
}

.radio-button-style:first-child {
  padding-top: 0;
}

.radio-button-style:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.radio-button-style .ant-radio-inner {
  background-color: #181b18 !important;
  border: 0.5px solid #303630;
  width: 18px;
  height: 18px;
}

.radio-button-style .ant-radio-inner::after {
  width: 30px;
  height: 30px;
  background-color: #00c802;
  inset-block-start: 8%;
  inset-inline-start: 8%;
}
.border-bottom-style {
  border-bottom: 1px solid #303630;
}

// animation progress bar left to right
@keyframes progress-bar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.progress-bar-animation {
  animation: progress-bar 1s ease-in-out;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-spin {
  animation: spin 2s linear infinite;
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animation-slide-left {
  animation: slideLeft 0.2s ease-in-out;
}

.currency_format_container {
  position: relative;
}

.currency_format_container:focus-within .currency_format {
  padding: 12px 34px 12px 8px;
}

.currency_format_container .clear_icon {
  display: none;
  pointer-events: none;
}

.currency_format_container:focus-within .clear_icon {
  display: block;
  pointer-events: auto;
}